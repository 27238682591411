import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item']
  static values = {
    timeout: {
      type: Number,
      default: 3500
    }
  }

  removeItem({ target }) {
    const item = this.itemTargets.find(item => item.contains(target))

    item?.remove()
  }

  itemTargetConnected() {
    setTimeout(() => {
      this.itemTarget.remove()
    }, this.timeoutValue)
  }

  itemTargetDisconnected() {
    if (this.element.children.length === 0) {
      this.element.remove()
    }
  }
}