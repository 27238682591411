import { Controller } from "@hotwired/stimulus";
import { formatNumber, parseFloatFromString } from "../helpers/number";
import strftime from "strftime";

export default class extends Controller {
  static targets = [
    'percentage',
    'amount',
    'date',
    'description'
  ]

  static values = {
    dateFormat: String,
    descriptionTemplate: String,
    totalPriceAfterDiscountCents: Number
  }

  calculateAmount() {
    const percentage = parseFloatFromString(this.percentageTarget.value)
    const price = this.totalPriceAfterDiscountCentsValue / 100
    const amount = (percentage / 100) * price

    this.amountTarget.innerText = isNaN(amount) ? 0 : formatNumber(amount)
  }

  populateDescription() {
    if (!this.dateTarget.valueAsDate) {
      this.descriptionTarget.value = ''
    } else {
      const date = strftime(this.dateFormatValue, this.dateTarget.valueAsDate)
      const description = this.descriptionTemplateValue.replace(/:date/g, date)

      this.descriptionTarget.value = description
    }

    this.descriptionTarget.dispatchEvent(new Event('input'))
  }
}