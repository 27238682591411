import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = {
    inputNames: Array,
    checkboxNames: Array,
    url: String,
    delay: Number
  }

  connect() {
    this.inputNamesValue.forEach((inputName) => {
      this.element.querySelectorAll(`input[name="${inputName}"]`).forEach((element) => {
        element.dataset.action ||= ''
        element.dataset.action += ' change->submitter#save'
      })
    })

    this.checkboxNamesValue.forEach((inputName) => {
      this.element.querySelectorAll(`input[type="checkbox"][name="${inputName}"]`).forEach((element) => { // Only add event listener for checkboxes
        element.dataset.action ||= ''
        element.dataset.action += ' change->submitter#save'
      })
    })
  }

  save() {
    const formData = new FormData()
    
    this.inputNamesValue.forEach((inputName) => {
      const input = this._checkForValue(inputName, 'input') || this._checkForValue(inputName, 'select');
      if (input) {
        formData.append(inputName, input.value);
      }
    });

    this.checkboxNamesValue.forEach((checkboxName) => {
      const checkbox = this._checkForValue(checkboxName, 'input[type="checkbox"]');
      if (checkbox) {
        formData.append(checkboxName, checkbox.checked ? '1' : '0');
      }
    });

    setTimeout(() => {
      this._calculate(formData);
    }, this.delayValue);
  }

  _checkForValue(inputName, type) {
    return this.element.querySelector(`${type}[name="${inputName}"]`)
  }

  _calculate(formData) {
    post(this.urlValue, {
      responseKind: 'turbo-stream',
      body: formData
    })
  }
}