import StimulusFlatpickr from "stimulus-flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import { ShortcutButtonsPlugin } from "shortcut-buttons-flatpickr/src/index";

export default class extends StimulusFlatpickr {
  static targets = ['startDate', 'endDate']

  initialize() {
    super.initialize()

    this.config.plugins = [
      new rangePlugin({ input: this.endDateTarget }),
      ShortcutButtonsPlugin({
        button: [
          { label: 'Today' },
          { label: 'Clear' }
        ],
        onClick(index, instance) {
          if (index == 0) {
            instance.setDate(new Date());
          } else {
            instance.clear();
          }
        }
      })

    ]
  }

  get flatpickrElement() {
    return this.startDateTarget
  }

  change() {
    this.dispatch('change')
  }
}