import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.disableForm();
  }

  disableForm() {
    const elements = this.element.elements;
    
    for (let element of elements) {
      element.disabled = true;
    }
  }
}