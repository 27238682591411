export function calculateMarginAndPercentage(price, cost) {
  const margin = calculateMargin(price, cost)
  const percentage = calculateMarginPercentage(price, cost)

  return { margin, percentage }
}

export function calculateMargin(price, cost) {
  return price - cost
}

export function calculateMarginPercentage(price, cost) {
  if (price === 0 && cost === 0) {
    return 0
  }

  if (price === 0) {
    return -100
  }

  return Math.round(((price - cost) / price) * 100)
}