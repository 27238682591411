import { Controller } from "@hotwired/stimulus";
import { calculateMarginAndPercentage } from "../helpers/marginCalculator";
import { formatNumber, parseFloatFromString } from "../helpers/number";

export default class extends Controller {
  static outlets = ['total-budget-calculator']
  static targets = [
    'price', 'totalPrice', 'duration',
    'cost', 'actualCost', 'totalCost',
    'quantity', 'margin', 'totalMargin'
  ];

  static values = {
    currency: String,
    dailyMultiplier: {
      type: Number,
      default: 1
    },
    quantityMultiplier: {
      type: Number,
      default: 1
    }
  }

  connect() {
    this._calculateTotalCost()
    this._calculateTotalPrice()
    this._calculateMargin()
    this._calculateTotalMargin()
  }

  calculate() {
    this._calculateTotalCost()
    this._calculateTotalPrice()
    this._calculateMargin()
    this._calculateTotalMargin()
    this._calculateTotalBudget()
  }

  _calculateTotalCost() {
    if (!this.hasTotalCostTarget) return

    const currency = this.currencyValue

    if (currency) {
      this.totalCostTarget.innerText = `${formatNumber(this.totalCost)} ${currency}`
    } else {
      this.totalCostTarget.innerText = formatNumber(this.totalCost)
    }
  }

  _calculateTotalPrice() {
    if (!this.hasTotalPriceTarget) return

    const currency = this.currencyValue

    if (currency) {
      this.totalPriceTarget.innerText = `${formatNumber(this.totalPrice)} ${currency}`
    } else {
      this.totalPriceTarget.innerText = formatNumber(this.totalPrice)
    }
  }

  _calculateMargin() {
    if (!this.hasMarginTarget) return

    const currency = this.currencyValue
    const { margin, percentage } = calculateMarginAndPercentage(
      this.price,
      this.cost
    )

    if (currency) {
      this.marginTarget.innerText = `${formatNumber(margin)} ${currency} (${percentage}%)`
      this.marginTarget.value = `${formatNumber(margin)} (${percentage}%)`
    } else {
      this.marginTarget.innerText = `${formatNumber(margin)} ${currency} (${percentage}%)`
      this.marginTarget.value = `${formatNumber(margin)} (${percentage}%)`
    }
  }

  _calculateTotalMargin() {
    if (!this.hasTotalMarginTarget) return;

    const currency = this.currencyValue;

    const { margin, percentage } = calculateMarginAndPercentage(
      this.totalPrice,
      this.totalCost
    )

    if (currency) {
      this.totalMarginTarget.innerText = `${formatNumber(margin)} ${currency} (${percentage}%)`
    } else {
      this.totalMarginTarget.innerText = `${formatNumber(margin)} (${percentage}%)`
    }
  }

  _calculateTotalBudget() {
    if (this.hasTotalBudgetCalculatorOutlet) {
      this.totalBudgetCalculatorOutlet.calculate()
    }
  }

  get quantity() {
    if (!this.hasQuantityTarget) {
      return this.quantityMultiplierValue
    }

    const value = this.quantityTarget.value

    if (value) {
      return parseFloatFromString(value) * this.quantityMultiplierValue
    } else {
      return this.quantityMultiplierValue
    }
  }

  get dailyMultiplier() {
    if (this.hasDurationTarget) {
      return this.durationTarget.value
    } else {
      return this.dailyMultiplierValue
    }
  }

  get price() {
    if (!this.hasPriceTarget) {
      return 0
    }

    const value = this.priceTarget.value

    if (value) {
      return parseFloatFromString(value)
    } else {
      return 0
    }
  }

  get cost() {
    if (!this.hasCostTarget) {
      return 0
    }

    const value = this.costTarget.value

    if (value) {
      return parseFloatFromString(value)
    } else {
      return 0
    }
  }

  get actualCost() {
    if (!this.hasActualCostTarget) {
      return 0;
    }

    const value = this.actualCostTarget.value

    if (value) {
      return parseFloatFromString(value)
    } else {
      return 0
    }
  }

  get totalPrice() {
    return this.price * this.quantity * this.dailyMultiplier
  }

  get totalCost() {
    if (this.actualCost > 0) {
      return this.actualCost
    }

    return this.cost * this.quantity * this.dailyMultiplier
  }
}