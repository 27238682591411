import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tabItem"]

  highlightTab(event) {
    if (event.target.classList.contains("cursor-not-allowed")) {
      return  nil
    }
    this.tabItems.forEach((tabItem) => {
      tabItem.classList.remove("text-blue-600", "border-blue-600", "active")
      tabItem.classList.add("border-transparent")
    })
    event.target.classList.add("text-blue-600", "border-blue-600", "active")
    event.target.classList.remove("border-transparent")
  }

  get tabItems() {
    return this.tabItemTargets
  }
}