import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.showModal()
  }

  close() {
    this.element.innerHTML = ''
    this.element.close()
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}