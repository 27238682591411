// Reference: https://github.com/thoughtbot/administrate/blob/main/app/assets/javascripts/administrate/controllers/table_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  visit(e) {
    if (e.type == "click" ||
      e.keyCode == keycodes.space ||
      e.keyCode == keycodes.enter) {

      if (e.target.href) {
        return;
      }

      const url = this.element.dataset.url

      var selection = window.getSelection().toString();
      if (url && selection.length === 0) {
        Turbo.visit(url);
      }
    }
  }
}