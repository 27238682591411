import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  async updatePrice(event) {
    const gid = event.target.value
    const id = gid.split('/').pop()

    const response = await fetch(`/admin/retreats/offerings/${id}`)
    const activity = await response.json()
    document.getElementById('budget_line_item_price').value = activity.price
  }
}