import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    selectables: {
      type: Array,
      default: []
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: Array,
      default: []
    },
    maxItems: Number,
    placeholder: String
  }

  initialize() {
    this._selectizeOnChange = this._selectizeOnChange.bind(this)
  }

  connect() {
    require('jquery-ui/dist/jquery-ui')

    // Add on change event listener to existing selectize instance
    // if selectize already initialized by administrate or other libraries
    // since selectize will dispatch to jquery event and jquery event its different
    // from native event, so we need to listen to it and dispatch custom event to native event.
    if (this.element.selectize) {
      this.element.selectize.on('change', this._selectizeOnChange)
    } else {
      this._initializeSelectize()
    }
  }

  disconnect() {
    this.element.selectize?.destroy()
  }

  fetchData(value) {
    get(`/admin/detailed_plans/${value}/get_lead`, {
      responseKind: 'turbo-stream',
    })
  }

  disconnect() {
    this.element.selectize?.destroy()
  }

  _initializeSelectize() {
    $(this.element).selectize({
      plugins: this.pluginsValue,
      delimiter: ",",
      maxItems: this.maxItemsValue || null,
      valueField: 'value',
      labelField: 'text',
      searchField: 'text',
      placeholder: this.placeholderValue,
      options: this.selectablesValue,
      persist: false,
      create: this._createOptionHandler(),
      onChange: this._selectizeOnChange
    });
  }

  _selectizeOnChange(value) {
    this.element.dispatchEvent(new CustomEvent('selectize:change'))

    if (this.customActionOnChangedValue) {
      this.fetchData(value)
    }
  }

  _createOptionHandler() {
    if (!this.allowCreateValue) {
      return false;
    }

    return (input) => ({ value: input, text: input })
  }
}