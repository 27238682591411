import { Controller } from "@hotwired/stimulus"
import * as requests from '@rails/request.js'

export default class extends Controller {

  static values = {
    overridePath: String,
    warnOnExit: Boolean
  }

  connect() {
    this._warnOnExit();
  }

  submitWithOverride(event) {
    const data = event.target.dataset
    const url = new URL(data.formOverridePath)

    url.searchParams.forEach((value, key) => {
      this._appendInput(value, key)
    });

    this.element.action = data.formOverridePath
    this.element.method = data.formOverrideMethod 

    if (data.formOverrideTurbo) {
      this.element.dataset.turboStream = true 
    } else {
      delete this.element.dataset.turboStream
    }
  }

  requestValidate() {
    const method = this.element.querySelector('[name="_method"]')?.value || this.element.method

    const body = new FormData(this.element)
    body.append('validate', true)

    requests[method](this.element.action, { body, responseKind: 'turbo-stream'})
  }

  _appendInput(value, key) {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;

    this.element.appendChild(input);
  }

  _warnOnExit() {
    if (!this.warnOnExitValue) { return }

    window.onbeforeunload = function(event) {
      if (event.target.activeElement.dataset.skipWarnOnExit) { return }

      return "Data you have entered may not be saved. If you need to navigate back, please click \"Previous\" below.";
    };
  }
}
