import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ['trigger', 'collection']

  connect() {
    this.fetchCollectionByObject()
  }

  change() {
    this.fetchCollectionByObject()
  }

  fetchCollectionByObject() {
    let triggerValue = this.triggerTarget.value.trim();
  
    if (!triggerValue) {
      return;
    }

    // TODO: Change the URL to dynamic sent by trigger target
    get(`/admin/regions/select_by_destination/${triggerValue}`, {
      responseKind: 'turbo-stream',
    })
  }
}