import { Controller } from "@hotwired/stimulus";
import { useDebounce, useMemo } from "stimulus-use";

export default class extends Controller {
  static outlets = ['selectize'];
  static memos = ['statusElement'];
  static debounces = ['populate'];

  connect() {
    useMemo(this);
    useDebounce(this);

    this.populate();
  }

  populate() {
    this.statusElement.selectize.unlock();

    if (this.element.value.match(/Offering/)) {
      this.statusElement.selectize.lock();
      this.statusElement.selectize.setValue('included');
    }
  }

  get statusElement() {
    return this.element.closest('.fields').querySelector('[id$="_status"]');
  }
}