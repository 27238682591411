import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['preview']

  showPreview({ target }) {
    const file = target.files[0]

    if (file) {
      this.imagePreview.src = URL.createObjectURL(file)
    }
  }

  get imagePreview() {
    return this.previewTarget.querySelector('img')
  }
}