import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  fetchLead() {
    const { value } = this.element

    get(`/admin/detailed_plans/${value}/get_lead`, {
      responseKind: 'turbo-stream',
    })
  }
}