import { Controller } from "@hotwired/stimulus";
import { calculateMarginAndPercentage } from "../helpers/marginCalculator";
import { parseFloatFromString, formatNumber } from "../helpers/number";

export default class extends Controller {
  static targets = ['price', 'cost', 'margin']

  static values = {
    margin: Number,
    currency: String
  }

  calculate() {
    const price = (1 + this.marginValue) * this.#cost
    this.#price = price.toFixed(1)
    this.dispatch('calculated')
  }

  calculateMargin() {
    if (!this.hasMarginTarget) return;

    const currency = this.currencyValue;
    const { margin, percentage } = calculateMarginAndPercentage(this.#price, this.#cost);

    if (currency) {
      this.marginTarget.innerText = `${formatNumber(margin)} ${currency} (${percentage}%)`;
    } else {
      this.marginTarget.innerText = `${formatNumber(margin)} (${percentage}%)`;
    }
  }

  get #cost() {
    return parseFloatFromString(this.costTarget.value || this.costTarget.innerText);
  }

  get #price() {
    return parseFloatFromString(this.priceTarget.value || this.priceTarget.innerText);
  }

  set #price(value) {
    if (this.priceTarget instanceof HTMLInputElement) {
      this.priceTarget.value = value;
    } else {
      this.priceTarget.innerText = value;
    }
  }
}