import { Controller } from "@hotwired/stimulus";
import Datepicker from '@themesberg/tailwind-datepicker/Datepicker';
import defaultOptions from "../components/default_options";

export default class extends Controller {
    static values = {
        config: {
            type: Object,
            default: defaultOptions,
        }
    }

    connect() {
        this.datePicker = new Datepicker(this.element, { ...defaultOptions, ...this.configValue });
        this.element.value = this.element.getAttribute('value')
        this._setDatePickerDate();
    }

    _setDatePickerDate() {
      if (this.element.value.length === 10) this.datePicker.setDate(this.element.value);
    }
}