import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["accommodation", "meetingRoom"];

  populate({ target: { value } }) {
    const accommodationUrl = new URL(this.accommodationTarget.src);
    const meetingRoomUrl = new URL(this.meetingRoomTarget.src);
    accommodationUrl.searchParams.set("partner", value);
    meetingRoomUrl.searchParams.set("partner", value);
    this.accommodationTarget.src = accommodationUrl;
    this.meetingRoomTarget.src = meetingRoomUrl;
  }
}
