export function formatNumber(number) {
  return Intl.NumberFormat('en-US').format(number)
}

export function parseFloatFromString(value) {
  const parsedValue = parseFloat(value.replace(/,/g, ''))

  return isNaN(parsedValue) ? 0 : parsedValue
}

export function sum(numbers) {
  return numbers.reduce((a, b) => a + b, 0)
}