import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  async populateInfo(event) {
    const gid = event.target.value
    // const id = gid.split('/').pop()

    const response = await fetch(`/admin/retreats/itinerary_events/populate_eventable_info?eventable=${gid}`);
    const eventable = await response.json();
    document.getElementById('itinerary_event_custom_event_name').value = eventable.name
    document.getElementById('itinerary_event_custom_event_description').value = eventable.description
  }
}