import { Controller } from "@hotwired/stimulus"
import { calculateMarginAndPercentage } from "../helpers/marginCalculator"
import { formatNumber, sum } from "../helpers/number"

export default class extends Controller {
  static outlets = ['budget-calculator']

  static targets = ['totalPrice', 'totalCost', 'totalMargin']

  budgetCalculatorOutletConnected() {
    this.calculate()
    this.element.dataset.budgetCount = this.filteredBudgetCalculatorOutlets.length
  }

  budgetCalculatorOutletDisconnected() {
    this.calculate()
    this.element.dataset.budgetCount = this.filteredBudgetCalculatorOutlets.length
  }

  calculate() {
    this._calculateTotalMargin()
    this._calculateTotalCost()
    this._calculateTotalPrice()
  }

  _calculateTotalCost() {
    if (this.hasTotalCostTarget) {
      this.totalCostTarget.innerText = formatNumber(this.totalCost)
    }
  }

  _calculateTotalPrice() {
    if (this.hasTotalPriceTarget) {
      this.totalPriceTarget.innerText = formatNumber(this.totalPrice)
    }
  }

  _calculateTotalMargin() {
    if (this.hasTotalMarginTarget) {
      const { margin, percentage } = calculateMarginAndPercentage(this.totalPrice, this.totalCost)
      this.totalMarginTarget.innerText = `${formatNumber(margin)} (${percentage}%)`
    }
  }

  get totalPrice() {
    return sum(this.filteredBudgetCalculatorOutlets.map(outlet => outlet.totalPrice))
  }

  get totalCost() {
    return sum(this.filteredBudgetCalculatorOutlets.map(outlet => outlet.totalCost))
  }

  get filteredBudgetCalculatorOutlets() {
    return this.budgetCalculatorOutlets.filter(outlet => outlet.element.querySelector('input[name*="[_destroy]"]').value !== 'true')
  }
}